import { NextSeo } from 'next-seo';
import { Fragment } from 'react';
import Image from 'next/image';
import Header from '../components/Header';
import Footer from '../components/Footer';

import css from '../styles/modules/404/404.module.scss';

const Page404 = () => {

  /** TEMPLATE */
  return (
    <Fragment>
      <NextSeo
        title="404 — Страница не найдена"
        description="К сожалению, запрашиваемая Вами страница не найдена. Пожалуйста, перейдите в существующий раздел"
        openGraph={{
          url: `https://${process.env.NEXT_PUBLIC_SITEDOMAIN}`,
          type: 'website',
          title: '404 — Страница не найдена',
          description:
            'К сожалению, запрашиваемая Вами страница не найдена. Пожалуйста, перейдите в существующий раздел',
          images: [
            {
              url: '/images/og-preview.png'
            }
          ],
          site_name: process.env.NEXT_PUBLIC_SITENAME
        }}
      />
      <Header />
      <main className="core">
        <section className="block">
          <div className={['fixed', css.content].filter(Boolean).join(' ')}>
            <h1 className={css.contentTitle}>Страница не найдена</h1>
            <div className={css.contentDescription}>
              Возможно, у вас опечатка в адресе страницы, или её просто не существует :(
            </div>
            <div className={css.contentImage}>
              <Image
                src="/images/404.svg"
                layout="fill"
                objectFit="cover"
                alt="Страница не найдена"
              />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </Fragment>
  );
};

export default Page404;
